<ng-container>
  <div class="box-form">
    <div class="title-section">
      <div class="title">
        <div class="titleSectionInfo">
          <p *ngIf="titleSection">{{ titleSection | translate }}</p>
          <svg-icon
            *ngIf="titleInfoTooltip"
            [pTooltip]="titleInfoTooltip"
            [escape]="false"
            [tooltipPosition]="'top'"
            [tooltipStyleClass]="'titleInfoTooltip'"
            src="assets/images/icons/info.svg"
          ></svg-icon>
        </div>
        <div class="box-switch" *ngIf="switch">
          <app-switch-button
            [item]="{
            id: itemData?.id ?? null,
            value: itemData?.active ?? true,
            item: itemData ?? null
          }"
            [disabledSwitch]="disabledSwitch"
            (sendAction)="sendActiveOrNotActiveElement($event)"
          ></app-switch-button>
          <span
            [ngClass]="{ span_activate: form[0]?.get('active')?.value }"
          >{{
              form[0]?.get('active')?.value
                ? ('active_' | translate)
                : ('desactive_' | translate)
            }}</span
          >
        </div>

        <div *ngIf="switch" class="separator"></div>
        <div class="box-check" *ngIf="form && form[0].get('allow_emit')">
          <app-checkbox
            [parentForm]="form[0]"
            [fCName]="'allow_emit'"
            [options]="{
                placeholder: 'Permitir emisiones_',
                id: 'emision',
                class: 'checkTTOO',
                val: form[0].get('allow_emit')
              }"
          ></app-checkbox>
        </div>
        <ng-container *ngIf="titleIcon?.name && titleIcon?.iata">
          <app-airline-icon
            [airline]="titleIcon.name"
            [iata]="titleIcon.iata"
          ></app-airline-icon>
        </ng-container>
      </div>
      <div class="button-saved-edit">
        <div
          class="valid_credential"
          *ngIf="
            (id && showCredentialValidation) ||
            (!credentialValidation && showCredentialValidation)
          "
        >
          <div
            class="check"
            [ngClass]="{ noValidation: !credentialValidation }"
          >
            <svg-icon
              [src]="
                credentialValidation
                  ? 'assets/images/icons/check.svg'
                  : 'assets/images/icons/close.svg'
              "
            ></svg-icon>
          </div>
          <p [ngClass]="{ noValidation: !credentialValidation }">
            {{
              (credentialValidation
                ? 'credentialsAutoLogin_'
                : 'noCredentialsAutoLogin_'
              ) | translate
            }}
          </p>
        </div>
        <button
          *ngIf="(!editingItem && !id) || !itemData"
          pRipple
          (click)="saveItem()"
          type="button"
          class="btn-primary-outline"
        >
          {{ saveButton | translate }}
        </button>
        <button
          *ngIf="editingItem"
          pRipple
          (click)="saveItem(id!)"
          type="button"
          class="btn-primary-outline"
        >
          {{ saveButton | translate }}
        </button>
        <button
          *ngIf="editingItem"
          pRipple
          (click)="editButtonControl('cancel')"
          type="button"
          class="btn-cancel-outline"
        >
          {{ 'cancel_' | translate }}
        </button>
        <button
          *ngIf="!editingItem && id && itemData"
          class="edit"
          (click)="editButtonControl('edit')"
          [disabled]="editingItem"
        >
          <svg-icon src="assets/images/icons/over_edit.svg"></svg-icon>
          {{ 'edit_' | translate }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="!id || (editingItem && id); else dataTemplate">
      <ng-container
        class="container-template"
        *ngIf="!loadingComponent; else loading"
      >
        <ng-container
          *ngFor="let formData of form; index as indexForm; last as last"
        >
          <div class="box-form-contain">
            <div class="title section-form">
              <p *ngIf="titlesSectionForm">{{ titlesSectionForm[indexForm] | translate }}</p>
            </div>
            <div class="form">
              <app-fields-collection
                [class]="'form-' + 'class'"
                [form]="formData"
                [inputs]="inputs[indexForm]"
              ></app-fields-collection>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #dataTemplate>
      <div
        class="container-template"
        *ngIf="form && !loadingComponent; else loading"
      >
        <ng-container
          *ngFor="
            let formData of form;
            index as indexForm;
            last as last;
            first as first
          "
        >
          <div
            class="box-data-contain box-form-contain"
            [ngClass]="{ 'mb-5': !last, noFirst: !first }"
          >
            <div class="title section-form">
              <p *ngIf="titlesSectionForm">{{ titlesSectionForm[indexForm] | translate }}</p>
            </div>
            <div class="form" *ngIf="!itemData">
              <app-fields-collection
                [class]="'form-' + 'class'"
                [form]="formData"
                [inputs]="inputs[indexForm]"
              ></app-fields-collection>
            </div>
            <div class="box-values" *ngIf="itemData">
              <div
                class="info"
                *ngFor="
                  let field of fieldsConfig[indexForm];
                  index as indexControl
                "
              >
                <div
                  *ngIf="itemData[field.keySpecial || field.key]"
                  class="data-item"
                >
                  <label>{{ field.label | translate }}</label>
                  <p>
                    <ng-container
                      *ngIf="
                        (field.keySpecial === 'phone' ||
                          field.key === 'phone') &&
                        itemData.phonePrefix
                      "
                    >
                      +{{ itemData.phonePrefix }}
                    </ng-container>
                    <ng-container *ngIf="field.key === 'country'; else defaultItemData">
                      {{ getCountryName(itemData[field.key]) }}
                    </ng-container>
                    <ng-template #defaultItemData>
                      <ng-container *ngIf="field?.hideKeyValue">
                        {{ '*'.repeat(itemData[field?.keySpecial || field?.key].length) }}
                      </ng-container>
                      <ng-container *ngIf="!field?.hideKeyValue">
                        {{ itemData[field?.keySpecial || field?.key] | translate }}
                      </ng-container>
                    </ng-template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
