<ng-container>
  <div [ngClass]="{ isVeci: isVeci }">
    <router-outlet></router-outlet>
    <app-footer *ngIf="hideFooter$ | async"></app-footer>
  </div>
</ng-container>
<ng-container *ngIf="loading.value">
  <app-loading-screen></app-loading-screen>
</ng-container>
<div class="floating-log" id="log-container">
  <div *ngFor="let log of logs">
    {{ log }}
  </div>
</div>
<!--Clarity tracking code-->
<script *ngIf="userLogin" type="text/javascript"></script>
