import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { take, tap } from 'rxjs/operators';
import { JwtService } from '../services/user/jwt.service';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { StorageService } from '../services/utils/storage.service';
import { AlertRefreshTokenService } from '../services/utils/alert-refresh-token/alert-refresh-token.service';
import { WebsocketService } from '../services/websocket/websocket.service';
import { supportedRoles } from '../configurations/configurations';
import moment from 'moment-timezone';
import { GenericChatService } from '../services/chat/generic-chat.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  loggedIn = false;
  valm = false;
  loggeIn$ = new Subject<boolean>();

  subscriptions: Subscription[] = [];

  sendRefreshToken = false;

  localstorageClearLogout = [
    'refresh_token',
    'token',
    'last-corporate-searchs',
    'currentCompany',
    'isCorporate',
    'currentUser',
    'advertisement',
    'transport_vertical',
    'refresh_token_updating',
    'closedServiceFeeModal',
    'RAUD',
    'noActiveProviders',
  ];
  shouldLooktoken = false;
  tokens: any;

  private lastUserActivityTime = moment();
  private worker: any;

  // constructor
  constructor(
    private http: HttpClient,
    private jwt: JwtService,
    private userService: UserService,
    private router: Router,
    private storageService: StorageService,
    private alerRefreshToken: AlertRefreshTokenService,
    private websocketService: WebsocketService,
    private ngZone: NgZone,
    private chatService: GenericChatService
  ) {
    this.worker = new Worker('./assets/js/worker.js');
    this.showAlertEndTime();
    this.detectUserActivity();
    // Listener para ir a la home cuando el usuario este conectado
    this.listenerTokenCreationOrChanges();
  }

  private listenerTokenCreationOrChanges(): void {
    this.ngZone.runOutsideAngular(() => {
      window.onstorage = (e) => {
        if (e?.key === 'currentUser' && this.isJWTTokenNotExpired()) {
          const newValue = JSON.parse(e?.newValue);
          const oldValue = JSON.parse(e?.oldValue);
          if (newValue !== null && newValue?.c?.ci !== oldValue?.c?.ci) {
            this.router.navigate([this.userService.getUserURLHome()]);
          }
        } else if (e?.key === 'token' && this.shouldLooktoken) {
          this.alerRefreshToken.closeModal();
          this.shouldLooktoken = false;
          this.isLoggedIn();
          this.showAlertEndTime();
        }
      };
    });
  }

  getIsLoggeIn$(): Observable<any> {
    return this.loggeIn$.asObservable();
  }

  getConnectedUserToken$(): Observable<any> {
    return this.jwt.connectedUserToken$.asObservable();
  }

  // is user loggedIn
  public isLoggedIn(): boolean {
    // Check whether the token is expired an return
    // true or false
    if (this.jwt.getToken() && !this.jwt.isTokenExpired()) {
      this.loggedIn = true;
      this.loggeIn$.next(true);
    } else {
      this.updateRefreshToken();
    }
    return this.loggedIn;
  }

  private updateRefreshToken(): void {
    if (!this.jwt.getToken() || this.jwt.isTokenExpired()) {
      this.logout();
    } else {
      this.refreshToken(this.jwt.getRefreshToken());
    }
  }

  isJWTTokenNotExpired(): boolean {
    return (this.jwt.getToken() && !this.jwt.isTokenExpired()) as boolean;
  }

  getLoggedInStatus(): boolean {
    if (this.jwt.getToken() && !this.jwt.isTokenExpired()) {
      this.loggedIn = true;
      this.loggeIn$.next(true);
    } else if (
      this.jwt.getRefreshToken() &&
      !this.jwt.isRefreshTokenExpired()
    ) {
      this.loggedIn = true;
      this.loggeIn$.next(true);
      this.isLoggedIn();
    } else {
      this.loggedIn = false;
      this.loggeIn$.next(false);
      this.isLoggedIn();
    }
    return this.loggedIn;
  }

  // login user.
  login(username: string, password: string): Observable<TokenResponse> {
    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    this.destroyLocastorage();

    return this.http
      .post<TokenResponse>(
        `${environment.api_backend_url}/api/login_check`,
        { _username: username, _password: password },
        { headers }
      )
      .pipe(
        tap((tokens: TokenResponse) => {
          this.jwt.setToken(tokens.token);
          this.jwt.setRefreshToken(tokens.refresh_token);
        })
      );
  }

  pinvalido() {
    this.jwt.setToken(this.tokens.token);
    this.jwt.setRefreshToken(this.tokens.refresh_token);
  }

  // recovery password
  sendPasswordResetLink(email: string) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.post<any>(
      `${environment.api_backend_url}/api/password/reset/request`,
      { username: email },
      { headers }
    );
  }

  // reset password
  resetPassword(password: string, token: string) {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.post<any>(
      `${environment.api_backend_url}/api/password/reset/confirm`,
      { token, password },
      { headers }
    );
  }

  // logout user.
  logout(): void {
    const tn = this.userService.getCurrentUSer()?.client?.tn;
    if (this.loggedIn) {
      this.redirectToLogin();
    }
    if (this.userService.userIsCallCenter()) {
      document.getElementById('fg_bubble')?.classList.remove('isCallCenter');
      this.chatService.logout('froged');
    }
    this.loggedIn = false;
    this.loggeIn$.next(false);
    this.userService.destroyUser();
    this.websocketService.closedWS();
    this.destroyLocastorage();

    if (tn === 'carrefour') {
      this.chatService.logout('froged');
      window.location.href = '/login';
    }
  }

  logoutNavegateVersionOne() {
    const user = JSON.parse(localStorage.getItem('currentUser')!);
    this.loggedIn = false;
    this.userService.destroyUser();
    this.destroyCompanyData();
    this.websocketService.closedWS();
    this.destroyLocastorage();

    document.location.href = this.generateAutologinV1URL(user);
  }

  destroyLocastorage() {
    this.localstorageClearLogout.forEach((element) => {
      if (!element.includes('transport_vertical')) {
        localStorage.removeItem(element);
      } else {
        this.storageService.removeTransportVertical('transport_vertical');
      }
    });
  }

  generateAutologinV1URL(user): string {
    if (!user?.c?.aiu || !user?.u?.nc || !user?.u?.wp) {
      return '/';
    }
    return `${user.c.aiu}/auto-login?e=${user.u.nc}&w=${user.u.wp}`;
  }

  redirectToLogin() {
    if (
      (this.userService.userIsCallCenter() && this.userService.isIberia()) ||
      this.userService.isRailAPP()
    ) {
      this.router.navigate(['/logout']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  private detectUserActivity() {
    const updateLastUserActivityTime = () => {
      this.lastUserActivityTime = moment();
      this.storageService.setItem(
        'lastUserActivityTime',
        JSON.stringify(this.lastUserActivityTime)
      );
    };
    window.onload = updateLastUserActivityTime;
    document.onmousemove = updateLastUserActivityTime;
  }

  showAlertEndTime(): void {
    this.worker.postMessage({ action: 'startInterval' });
    this.worker.onmessage = (event: MessageEvent) => {
      if (event.data.action === 'executeinterval') {
        this.handleWorkerMessage();
      }
    };
  }

  handleWorkerMessage(): void {
    const timeSavedInLocalStorage = moment(
      JSON.parse(this.storageService.getItem('lastUserActivityTime')!)
    );
    if (!this.loggedIn) {
      return;
    }
    if (!this.jwt.isTokenAlmostExpired()) {
      return;
    }
    if (moment().diff(timeSavedInLocalStorage) >= 120_000) {
      this.worker.postMessage({ action: 'stopInterval' });
      this.alerRefreshToken.showModal();
      this.shouldLooktoken = true;
      const closeModal = setInterval(() => {
        if (!this.jwt.isTokenAlmostExpired()) {
          clearInterval(closeModal);
          this.alerRefreshToken.closeModal();
        }
      }, 1000);
      this.alerRefreshToken
        .subscribeButton()
        .pipe(take(1))
        .subscribe((res) => {
          if (res === 'logout') {
            this.logout();
            this.alerRefreshToken.closeModal();
          } else {
            this.shouldLooktoken = false;
            this.updateRefreshToken();
            this.alerRefreshToken.closeModal();
            this.isLoggedIn();
            this.showAlertEndTime();
          }
        });
    } else if (!this.storageService.getItem('refresh_token_updating')) {
      this.shouldLooktoken = false;
      this.updateRefreshToken();
    }
  }

  // refresh token from api.
  private refreshToken(token): void {
    if (
      this.jwt.isTokenExpired() ||
      this.storageService.getItem('refresh_token_updating')
    ) {
      return;
    }

    this.storageService.setItem('refresh_token_updating', 'updating');

    // avoid getting the general error modal
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    if (this.sendRefreshToken) {
      return;
    } else {
      this.sendRefreshToken = true;
    }
    this.http
      .post<TokenResponse>(
        `${environment.api_backend_url}/api/token/refresh`,
        { refresh_token: token },
        { headers }
      )
      .subscribe({
        next: (tokens) => {
          this.jwt.setToken(tokens.token);
          this.jwt.setRefreshToken(tokens.refresh_token);
          this.loggedIn = true;
          this.sendRefreshToken = false;
          this.storageService.removeItem('refresh_token_updating');
        },
        error: () => {
          this.sendRefreshToken = false;
          this.storageService.removeItem('refresh_token_updating');
        },
      });
  }

  destroyCompanyData() {
    this.storageService.removeItem('client_static_data');
  }
}

interface TokenResponse {
  token: string;
  refresh_token: string;
}
